/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import style from './index.module.scss';
import Between from '@components/ui/Between';
import { Link } from 'react-router-dom';
import { GetContractList } from '@src/utils/apis';
import { ContractItem } from '@src/models';
import { formatUnixWithoutYear } from '@src/utils/tools';
import right from '@imgs/right.png';
import TWEEN from '@tweenjs/tween.js';
import { useSelector } from 'react-redux';
import { RootReducer } from '@src/store';
import { Bubble } from 'tea-component';
let timeManage: any;

export default function Contracts() {
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId = useMemo(() => currentChain?.ChainId, [currentChain]);
  const authType = useMemo(() => currentChain?.AuthType, [currentChain]);
  const [refreshCount, setRefreshCount] = useState<number>(0);
  const [contracts, setContracts] = useState<Array<ContractItem | null>>([
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ]);
  const [listTop, setListTop] = useState<number>(0);
  // 触发开始递归刷新数据
  const triggerUpdate = useCallback(() => {
    clearTimeout(timeManage);
    timeManage = setTimeout(() => {
      setRefreshCount(refreshCount + 1);
    }, 3000);
  }, [refreshCount]);
  /**
   * @param 更新的列表数据
   * 开始
   */
  const moveList = useCallback(
    (list: Array<ContractItem | null>) => {
      if (list.length) {
        // 记录久数组第一条数据对于新数组中的位置
        let number: number | null = null;
        let newContracts = list;
        for (let i = 0; i < 10; i++) {
          const contract = list[i];
          if (contract?.Id === contracts[0]?.Id) {
            number = i;
            break;
          }
        }
        if (number !== 0) {
          if (number === null) {
            number = newContracts.length;
            newContracts = newContracts.concat(contracts);
          } else {
            newContracts = newContracts.slice(0, number).concat(contracts);
          }
          const left = number * -98;
          setContracts(newContracts);
          setListTop(left);
          new TWEEN.Tween({ left })
            .to({ left: 0 }, 1000)
            .duration(1500)
            .easing(TWEEN.Easing.Cubic.Out)
            .onUpdate((info) => {
              setListTop(info.left);
              if (info.left === 0) {
                setContracts(newContracts.slice(0, 10));
              }
            })
            .start();
        }
      }
      triggerUpdate();
    },
    [contracts, triggerUpdate],
  );
  const updateList = useCallback(() => {
    GetContractList({
      ChainId: chainId,
      Limit: 10,
      Order: 'desc',
    }).then((res) => {
      if (res.GroupList) {
        moveList(res.GroupList);
      }
    });
  }, [chainId, moveList]);

  const stopMove = useCallback(() => {
    clearTimeout(timeManage);
  }, []);
  useEffect(() => {
    updateList();
    return () => {
      stopMove();
    };
  }, [refreshCount]);

  return (
    <div className={style.box}>
      <Between
        title="最新合约"
        content={
          <Link className={style.more} to={`/${chainId}/contract`}>
            更多
            <img src={right} />
          </Link>
        }
      />
      <div className={style.list}>
        <div
          style={{ top: listTop }}
          className={style.contract_list}
          onMouseEnter={stopMove}
          onMouseLeave={triggerUpdate}
        >
          {contracts.map((contract, index) =>
            contract
              ? (
                <div key={index} className={style.item}>
                  <div className={style.info}>
                    <div className={style.left}>
                      <div className={style.title}>
                        <Link to={`/${chainId}/contract/${contract.Name}`}>{contract.Name}</Link>
                      </div>
                      <div className={style.version}>{contract.Version}</div>
                    </div>
                    <div className={style.time}>{formatUnixWithoutYear(contract.CreateTimestamp)}</div>
                  </div>
                  <div className={style.info}>
                    <div className={style.path}>
                      <Bubble content={contract.CreatorAddr}>
                        {authType === 'permissionedwithcert' ? contract.Creator || '--' : contract.CreatorAddr || '--'}
                      </Bubble>
                    </div>
                    <div className={style.name}>{contract.TxCount}Txns</div>
                  </div>
                </div>
              )
              : (
                <div key={index} className={style.item}></div>
              ),
          )}
          {contracts.length === 0 && <div className="empty_list"></div>}
        </div>
      </div>
    </div>
  );
}
