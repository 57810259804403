import { Search } from '@src/utils/apis';
import React, { ReactElement, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RootReducer } from '@src/store';

export default function SearchLink({ children, keyWord }: { children: string | ReactElement; keyWord?: string }) {
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId = useMemo(() => currentChain?.ChainId, [currentChain]);
  const navigate = useNavigate();
  const triggerSearch = useCallback(() => {
    Search({
      Id: keyWord || (children as string),
      ChainId: chainId,
    }).then((res) => {
      if (res.Data?.Type !== undefined) {
        switch (res.Data.Type) {
          case 0:
            navigate(`/${res.Data.ChainId}/block/${res.Data.Data}`);
            break;
          case 1:
            navigate(`/${res.Data.ChainId}/transaction/${res.Data.Data}`);
            break;
          case 2:
            navigate(`/${res.Data.ChainId}/contract/${res.Data.Data}`);
            break;
          // case 3:
          //   navigate(`/${res.Data.ChainId}/contract/${res.Data.Data}`);
          //   break;
          default:
            break;
        }
      }
    });
  }, [children, keyWord]);
  return <a onClick={triggerSearch}>{children}</a>;
}
