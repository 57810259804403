/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import { ContractStatus } from '@src/utils/enums';
import { ParamWithChainId, ValueOf } from '.';
import { PagingWithChainId } from './common';

export type GetLatestContractListParam = ParamWithChainId;

export enum EnumContractType {
  CMDFA = 'CMDFA',
  CMNFA = 'CMNFA',
  CMID = 'CMID',
  CMEVI = 'CMEVI',
  Other = 'Other',
}

export interface GetLatestContractListItem {
  /**
   * 创建者
   */
  Sender: string;
  /**
   * 创建者地址
   */
  SenderAddr: string;
  /**
   * 合约地址
   */
  ContractAddr: string;
  /**
   * 合约名
   */
  ContractName?: string;
  /**
   * 合约原始名
   */
  ContractNameBak?: string;
  /**
   * 当前版本号
   */
  Version: string;
  /**
   * 合约交易数量
   */
  TxNum: number;
  /**
   * 时间戳
   */
  Timestamp: number;
}
export interface GetContractListParam extends PagingWithChainId {
  /**
   * 合约名
   */
  ContractName?: string;
  /**
   * 升序还是降序排列
   */
  Order?: 'desc' | 'asc';
}
export interface ContractItem {
  /**
   * 逻辑ID
   */
  Id: number;
  /**
   * 合约名
   */
  Name: string;
  /**
   * 合约地址
   */
  Addr: string;
  /**
   * 当前版本号
   */
  Version: string;
  /**
   * 合约交易数量
   */
  TxCount: number;
  /**
   * 创建者
   */
  Creator: string;
  /**
   * 创建者地址
   */
  CreatorAddr: string;
  /**
   * 创建时间戳
   */
  CreateTimestamp: number;
  /**
   * 升级合约操作人
   */
  UpgradeUser: string;
  /**
   * 升级时间戳
   */
  UpgradeTimestamp: number;
  UpdateTimestamp: number;
  /**
   * 合约类型
   */
  ContractType: EnumContractType;
}

export interface GetContractDetailParam extends ParamWithChainId {
  /**
   * 合约名
   */
  ContractName: string;
}

export interface ContractInfo {
  /**
   * 合约名
   */
  Name: string;
  /**
   * 合约地址
   */
  Addr: string;
  /**
   * 当前版本号
   */
  Version: string;
  /**
   * 合约交易数量
   */
  TxCount: number;
  /**
   * 创建者
   */
  Creator: string;
  /**
   * 创建者地址
   */
  CreatorAddr: string;
  /**
   * 创建交易id
   */
  CreateTxId: string;
  /**
   * 创建时间戳
   */
  CreateTimestamp: number;
  /**
   * 升级合约操作人
   */
  UpgradeUser: string;
  /**
   * 升级时间戳
   */
  UpgradeTimestamp: number;
  /**
   * 合约状态码
   */
  ContractStatus: keyof typeof ContractStatus;
  /**
   * 合约状态
   */
  ContractStatusText?: ValueOf<typeof ContractStatus>;
  /**
   * 虚拟机类型
   */
  RuntimeType: string;
  /**
   * 合约类型
   */
  ContractType: EnumContractType;
  /**
   * 合约简称
   */
  ContractSymbol: string;
  /**
   * Token简称
   */
  TokenAbbreviation: string;
  /**
   * 总发行量
   */
  TotalSupply: string;
}
