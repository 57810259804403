/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import moment from 'moment';
export const formatTime = (date?: number) => (date !== undefined ? moment(date).format('YYYY-MM-DD HH:mm:ss') : '--');
export const formatUnixWithoutYear = (date?: number) =>
  date !== undefined ? moment.unix(date).format('MM-DD HH:mm:ss') : '--';
export const formatUnixTime = (date?: number) =>
  date !== undefined ? moment.unix(date).format('YYYY-MM-DD HH:mm:ss') : '--';
