/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import React, { useCallback } from 'react';
import SubscribeForm from '@src/components/form/SubscribeForm';
import { SubscribeChain } from '@src/utils/apis';
import style from './index.module.scss';
import { Subscribe } from '@src/models';
import { useNavigate } from 'react-router-dom';
import { message } from 'tea-component';
import { useDispatch } from 'react-redux';

export default function AddChain() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onCancel = useCallback(() => {}, []);

  const updateChainSelect = useCallback(() => {
    dispatch({
      type: 'GET_CHAINS',
      payload: {
        Limit: 99999,
      },
    });
  }, []);
  const onSubmit = useCallback((subscribe: Subscribe) => {
    SubscribeChain(subscribe).then((res) => {
      if (res.Data) {
        setTimeout(() => {
          updateChainSelect();
        }, 500);
        message.loading({
          content: '订阅中 ...',
          duration: 2000,
        });
        setTimeout(() => {
          navigate('/v');
        }, 2000);
      }
    });
  }, []);
  return (
    <div className={style.chain}>
      <div className={style.title}>浏览器初始化配置</div>
      <div className={style.subtitle}>
        请先配置你要订阅的链的相关信息，才可查看链上的数据，如需订阅多条链，后续可在区块链管理处继续添加订阅。
      </div>
      <SubscribeForm onSubmit={onSubmit} onCancel={onCancel} submitText="订阅链" />
    </div>
  );
}
