/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import liststyle from '../../list.module.scss';
import { Text, Table, TableColumn, Bubble } from 'tea-component';
import { Tx } from '@src/models';
import { GetTxList } from '@src/utils/apis';
import { useParams, Link } from 'react-router-dom';
import { formatUnixTime } from '@src/utils/tools';
import { useSelector } from 'react-redux';
import { RootReducer } from '@src/store';
const { pageable } = Table.addons;

export default function Transaction() {
  const { contractName } = useParams();
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId = useMemo(() => currentChain?.ChainId, [currentChain]);
  const authType = useMemo(() => currentChain?.AuthType, [currentChain]);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [list, setList] = useState<Tx[]>([]);
  const getList = useCallback(() => {
    GetTxList({
      Offset: pageIndex - 1,
      ChainId: chainId,
      ContractName: contractName,
      Limit: pageSize,
    }).then((res) => {
      if (res.GroupList) {
        setList(res.GroupList);
        setRecordCount(res.TotalCount);
      }
    });
  }, [chainId, contractName, pageIndex, pageSize]);
  useEffect(() => {
    getList();
  }, [chainId, pageIndex, pageSize]);
  const columns = useMemo<TableColumn<Tx>[]>(() => {
    const list: TableColumn<Tx>[] = [
      {
        key: 'Timestamp',
        header: '上链时间',
        align: 'left',
        render: ({ Timestamp }) => formatUnixTime(Timestamp),
        width: 180,
      },
      {
        key: 'TxId',
        header: '交易Id',
        align: 'left',
        render: ({ TxId }) => <Link to={`/${chainId}/transaction/${TxId}`}>{TxId}</Link>,
      },
      {
        key: 'UserAddr',
        header: '发起用户',
        align: 'left',
        width: 260,
        render:
          authType === 'permissionedwithcert'
            ? ({ Sender, UserAddr }) => <Bubble content={UserAddr}>{Sender}</Bubble>
            : undefined,
      },
      {
        key: 'ContractMethod',
        header: '调用方法',
        align: 'left',
        width: 150,
      },
      {
        key: 'Status',
        header: '交易状态',
        align: 'left',
        width: 140,
        render: ({ Status }) => <Text theme={Status === 'SUCCESS' ? 'success' : 'danger'}>{Status}</Text>,
      },
      {
        key: 'Id',
        header: '操作',
        align: 'center',
        width: 100,
        render: ({ TxId }) => <Link to={`/${chainId}/transaction/${TxId}`}>查看</Link>,
      },
    ];
    if (authType === 'permissionedwithcert') {
      list.splice(1, 0, {
        key: 'SenderOrg',
        header: '发起组织',
        align: 'left',
      });
    }
    return list;
  }, []);
  return (
    <div className={liststyle.table}>
      <Table
        style={{
          marginTop: 24,
        }}
        className={liststyle.table}
        compact={false}
        records={list}
        recordKey="Id"
        bordered={true}
        disableTextOverflow={true}
        columns={columns}
        addons={[
          pageable({
            recordCount,
            onPagingChange: (query) => {
              if (query?.pageIndex) {
                setPageIndex(query.pageIndex);
              }
              if (query?.pageSize) {
                setPageSize(query.pageSize);
              }
            },
          }),
        ]}
      />
    </div>
  );
}
