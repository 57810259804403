/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import liststyle from '../list.module.scss';
import PageBox from '@src/components/ui/PageBox';
import { Text, Justify, Table, TableColumn, Bubble } from 'tea-component';
import SearchInput from '@components/ui/SearchInput';
import { BlockItem } from '@src/models';
import { GetBlockList } from '@src/utils/apis';
import { Link } from 'react-router-dom';
import { formatUnixTime } from '@src/utils/tools';
import { RootReducer } from '@src/store';
import { useSelector } from 'react-redux';
const { pageable, autotip } = Table.addons;

export default function Block() {
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId = useMemo(() => currentChain?.ChainId, [currentChain]);
  const authType = useMemo(() => currentChain?.AuthType, [currentChain]);
  const [value, setValue] = useState<string>('');
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [list, setList] = useState<BlockItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const getList = useCallback(() => {
    setIsLoading(true);
    GetBlockList({
      Block: value,
      ChainId: chainId,
      Limit: pageSize,
      Offset: pageIndex - 1,
    })
      .then((res) => {
        setIsLoading(false);
        if (res.GroupList) {
          setList(res.GroupList);
          setRecordCount(res.TotalCount);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [chainId, value, pageIndex, pageSize]);
  useEffect(() => {
    getList();
  }, [chainId, pageIndex, pageSize]);
  const columns = useMemo<TableColumn[]>(
    () => [
      {
        key: 'BlockHeight',
        header: '区块高度',
        align: 'left',
        width: 100,
        render: ({ BlockHeight }) => <Link to={`/${chainId}/block/${BlockHeight}`}>{BlockHeight}</Link>,
      },
      {
        key: 'BlockHash',
        header: '区块Hash',
        align: 'left',
        render: ({ BlockHeight, BlockHash }) => <Link to={`/${chainId}/block/${BlockHeight}`}>{BlockHash}</Link>,
      },
      {
        key: 'TxCount',
        header: '交易数',
        align: 'left',
        width: 100,
      },
      {
        key: 'ProposalNodeAddr',
        header: '出块节点',
        align: 'left',
        render:
          authType === 'permissionedwithcert'
            ? ({ ProposalNodeId, ProposalNodeAddr }) => <Bubble content={ProposalNodeAddr}>{ProposalNodeId}</Bubble>
            : undefined,
      },
      {
        key: 'Timestamp',
        header: '区块生成时间',
        align: 'left',
        width: 200,
        render: ({ Timestamp }) => formatUnixTime(Timestamp),
      },
      {
        key: 'Id',
        header: '操作',
        align: 'center',
        width: 160,
        render: ({ BlockHeight }) => (
          <div className={liststyle.setting_c}>
            <Link to={`/${chainId}/block/${BlockHeight}`}>
              <Text theme="primary">查看</Text>
            </Link>
          </div>
        ),
      },
    ],
    [],
  );
  return (
    <PageBox title="区块列表">
      <Justify
        right={
          <SearchInput
            placeholder="请输入区块哈希/区块号搜索"
            onChange={(value) => setValue(value)}
            onSubmit={getList}
            value={value}
          />
        }
      />
      <div className={liststyle.table}>
        <Table
          style={{
            marginTop: 24,
          }}
          className={liststyle.table}
          compact={false}
          records={list}
          recordKey="Id"
          bordered={true}
          disableTextOverflow={true}
          columns={columns}
          addons={[
            autotip({
              isLoading,
            }),
            pageable({
              recordCount,
              pageIndex,
              onPagingChange: (query) => {
                if (query?.pageIndex) {
                  setPageIndex(query.pageIndex);
                }
                if (query?.pageSize) {
                  setPageSize(query.pageSize);
                }
              },
            }),
          ]}
        />
      </div>
    </PageBox>
  );
}
