/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import liststyle from '../list.module.scss';
import PageBox from '@src/components/ui/PageBox';
import { Button, Form, Input, Justify, Select, Table, TableColumn } from 'tea-component';
import { NodeItem } from '@src/models';
import { GetNodeList, GetOrgList } from '@src/utils/apis';
import { formatAddress, formatUnixTime } from '@src/utils/tools';
import { useSelector } from 'react-redux';
import { RootReducer } from '@src/store';
const { pageable, autotip } = Table.addons;

export default function Chain() {
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId = useMemo(() => currentChain?.ChainId, [currentChain]);
  const authType = useMemo(() => currentChain?.AuthType, [currentChain]);
  const [nodeName, setNodeName] = useState('');
  const [nodeId, setNodeId] = useState('');
  const [orgId, setOrgId] = useState('');
  const [options, setOptions] = useState<any[]>([]);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState<NodeItem[]>([]);
  const getList = useCallback(() => {
    setIsLoading(true);
    const query = {
      ChainId: chainId,
      Offset: pageIndex - 1,
      Limit: pageSize,
      NodeName: nodeName,
      NodeId: nodeId,
      OrgId: orgId,
    };
    // if (authType === 'permissionedwithcert') {
    //   query.NodeName = value;
    // }else{
    //   query.NodeId = value;
    // }
    GetNodeList(query)
      .then((res) => {
        setIsLoading(false);
        if (res.GroupList) {
          setList(res.GroupList);
          setRecordCount(res.TotalCount);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [chainId, orgId, nodeName, nodeId, pageIndex, pageSize]);
  useEffect(() => {
    getList();
  }, [pageIndex, chainId, pageSize]);
  useEffect(() => {
    if (authType !== 'permissionedwithcert') {
      return;
    }
    GetOrgList({
      ChainId: chainId,
      Offset: 0,
      Limit: 99999,
    }).then((res) => {
      if (res.GroupList) {
        setOptions(
          res.GroupList.map((item) => ({
            value: item.OrgId,
            text: item.OrgId,
          })),
        );
      }
    });
  }, [authType]);
  const columns = useMemo<TableColumn<NodeItem>[]>(() => {
    const list: TableColumn<NodeItem>[] = [
      {
        key: 'NodeId',
        header: '节点Id',
        align: 'left',
      },
      // {
      //   key: 'NodeName',
      //   header: '节点名称',
      //   align: 'left',
      // },
      {
        key: 'NodeAddress',
        header: '节点IP和端口',
        align: 'left',
        render: ({ NodeAddress }) => formatAddress(NodeAddress),
      },
      {
        key: 'Role',
        header: '节点角色',
        align: 'left',
        width: 160,
      },
    ];
    if (authType === 'permissionedwithcert') {
      list.splice(1, 0, {
        key: 'NodeName',
        header: '节点名称',
        align: 'left',
      });
      list.push({
        key: 'OrgId',
        header: '所属组织',
        align: 'left',
      });
    } else {
      list.push({
        key: 'Timestamp',
        header: '创建时间',
        align: 'left',
        render: ({ Timestamp }) => formatUnixTime(Timestamp),
      });
    }
    return list;
  }, [authType]);
  return (
    <PageBox title="节点列表">
      <Justify
        right={
          <>
            <Form className={liststyle.searchform} hideLabel={false} layout="inline">
              {authType === 'permissionedwithcert' && (
                <Form.Item label="所属组织">
                  <Select
                    options={options}
                    clearable
                    matchButtonWidth
                    appearance="button"
                    size="m"
                    onChange={(value) => setOrgId(value)}
                    value={orgId}
                  />
                </Form.Item>
              )}
              {authType === 'permissionedwithcert' && (
                <Form.Item label="节点名称">
                  <Input onChange={(value) => setNodeName(value)} value={nodeName} placeholder="请输入请输入节点名称" />
                </Form.Item>
              )}
              <Form.Item label="节点Id">
                <Input onChange={(value) => setNodeId(value)} value={nodeId} placeholder="请输入节点Id搜索" />
              </Form.Item>
              <Form.Item label="">
                <Button type="primary" onClick={getList}>
                  筛选
                </Button>
              </Form.Item>
            </Form>
          </>
        }
      />
      <div className={liststyle.table}>
        <Table
          style={{
            marginTop: 24,
          }}
          className={liststyle.table}
          compact={true}
          records={list}
          recordKey="Id"
          bordered={true}
          disableTextOverflow={true}
          columns={columns}
          addons={[
            autotip({
              isLoading,
            }),
            pageable({
              recordCount,
              pageIndex,
              onPagingChange: (query) => {
                if (query?.pageIndex) {
                  setPageIndex(query.pageIndex);
                }
                if (query?.pageSize) {
                  setPageSize(query.pageSize);
                }
              },
            }),
          ]}
        />
      </div>
    </PageBox>
  );
}
