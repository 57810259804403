/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import liststyle from '../list.module.scss';
import PageBox from '@components/ui/PageBox';
import { Text, Justify, Table, TableColumn, Bubble, Button } from 'tea-component';
import SearchInput from '@components/ui/SearchInput';
import { ContractItem } from '@src/models';
import { GetContractList } from '@src/utils/apis';
import { Link } from 'react-router-dom';
import { formatUnixTime } from '@src/utils/tools';
import { useSelector } from 'react-redux';
import { RootReducer } from '@src/store';
import SearchLink from '@src/components/form/SearchLink';
import { checkInstallPlugin } from '../components/tools';
import PluginDownloadModel from '@src/components/plugin/download-plugin-model';
const { pageable, autotip } = Table.addons;

export default function Chain() {
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId = useMemo(() => currentChain?.ChainId, [currentChain]);
  const authType = useMemo(() => currentChain?.AuthType, [currentChain]);
  const [value, setValue] = useState('');
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [list, setList] = useState<ContractItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [pluginDownloadVisible, setPluginDownloadVisible] = useState(false);
  const getList = useCallback(() => {
    setIsLoading(true);
    GetContractList({
      ContractName: value,
      ChainId: chainId,
      Limit: pageSize,
      Offset: pageIndex - 1,
      Order: 'desc',
    })
      .then((res) => {
        setIsLoading(false);
        if (res.GroupList) {
          setList(res.GroupList);
          setRecordCount(res.TotalCount);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [chainId, value, pageIndex, pageSize]);
  const listenContract = useCallback(
    (contractName: string, contractType: string) => {
      if (checkInstallPlugin()) {
        (window as any).chainMaker.sendRequest('importSubscribeContract', {
          body: {
            chainId,
            contractName,
            contractType,
          },
        });
      } else {
        setPluginDownloadVisible(true);
      }
    },
    [chainId],
  );
  useEffect(() => {
    getList();
  }, [chainId, pageIndex, pageSize]);
  const columns = useMemo<TableColumn<ContractItem>[]>(
    () => [
      {
        key: 'Name',
        header: '合约名称',
        align: 'left',
        width: 160,
        render: ({ Name, Addr }) =>
          Addr ? <Link to={`/${chainId}/contract/${Name}`}>{Name}</Link> : <SearchLink>{Name}</SearchLink>,
      },
      {
        key: 'Addr',
        header: '合约地址',
        align: 'left',
        render: ({ Addr }) => Addr || '--',
      },
      {
        key: 'Version',
        header: '当前版本号',
        align: 'left',
        width: 120,
        render: ({ Version }) => Version || '--',
      },
      {
        key: 'ContractType',
        header: '合约类型',
        align: 'left',
        width: 100,
      },
      {
        key: 'TxCount',
        header: '累计交易数',
        align: 'left',
        width: 120,
      },
      {
        key: 'CreatorAddr',
        header: '创建用户',
        align: 'left',
        render:
          authType === 'permissionedwithcert' ? ({ Creator, CreatorAddr }) => <Bubble content={CreatorAddr}>{Creator}</Bubble> : ({ CreatorAddr }) => CreatorAddr || '--',
      },
      {
        key: 'CreateTimestamp',
        header: '创建时间',
        align: 'left',
        width: 180,
        render: ({ CreateTimestamp }) => (CreateTimestamp ? formatUnixTime(CreateTimestamp) : '--'),
      },
      // {
      //   key: 'UpgradeUser',
      //   header: '最新更新用户',
      //   align: 'left',
      //   render: ({ UpgradeUser }) => UpgradeUser || '--',
      // },
      // {
      //   key: 'UpgradeTimestamp',
      //   header: '最新更新时间',
      //   align: 'left',
      //   width: 180,
      //   render: ({ UpgradeTimestamp }) => (UpgradeTimestamp ? formatUnixTime(UpgradeTimestamp) : '--'),
      // },
      {
        key: 'Id',
        header: '操作',
        align: 'center',
        width: 160,
        render: ({ Name, ContractType }) => (
          <div className={liststyle.setting_c}>
            <Link to={`/${chainId}/contract/${Name}`}>
              <Text theme="primary">查看</Text>
            </Link>
            <Button type="link" onClick={() => listenContract(Name, ContractType)}>
              添加到插件
            </Button>
          </div>
        ),
      },
    ],
    [],
  );
  return (
    <>
      <PageBox title="合约列表">
        <Justify
          right={
            <SearchInput
              placeholder="请输入合约名称搜索"
              onChange={(value) => setValue(value)}
              onSubmit={getList}
              value={value}
            />
          }
        />
        <div className={liststyle.table}>
          <Table
            style={{
              marginTop: 24,
            }}
            className={liststyle.table}
            compact={false}
            records={list}
            recordKey="Id"
            bordered={true}
            disableTextOverflow={true}
            columns={columns}
            addons={[
              autotip({
                isLoading,
              }),
              pageable({
                recordCount,
                pageIndex,
                onPagingChange: (query) => {
                  if (query?.pageIndex) {
                    setPageIndex(query.pageIndex);
                  }
                  if (query?.pageSize) {
                    setPageSize(query.pageSize);
                  }
                },
              }),
            ]}
          />
        </div>
      </PageBox>
      <PluginDownloadModel visible={pluginDownloadVisible} onClose={() => setPluginDownloadVisible(false)}/>
    </>
  );
}
