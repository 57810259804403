/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import style from './index.module.scss';
import { useNavigate } from 'react-router-dom';
import { Decimal } from '@src/utils/apis';
import { ChainInfo } from '@src/models';
import { formatNumberToThousands } from '@src/utils/tools';
import TWEEN from '@tweenjs/tween.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowsRotate,
  faCubes,
  faFileContract,
  // faUsers,
  // faBuildingColumns,
  // faAtom,
} from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { RootReducer } from '@src/store';
let timeManage: any;
export default function Statistics() {
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId = useMemo(() => currentChain?.ChainId, [currentChain]);
  // const authType = useMemo(() => currentChain?.AuthType, [currentChain]);
  const navigate = useNavigate();
  const [detail, setDetail] = useState<ChainInfo | null>(null);
  const [refreshCount, setRefreshCount] = useState<number>(0);
  const [waitTime, setWaitTime] = useState<number>(1000);
  // 数字动画函数
  const animateCount = useCallback(
    (data: ChainInfo) => {
      const from = {
        // detail ||
        BlockHeight: detail?.BlockHeight === data.BlockHeight ? data.BlockHeight : 0,
        TransactionNum: detail?.TransactionNum === data.TransactionNum ? data.TransactionNum : 0,
        ContractNum: detail?.ContractNum === data.ContractNum ? data.ContractNum : 0,
        OrgNum: detail?.OrgNum === data.OrgNum ? data.OrgNum : 0,
        UserNum: detail?.UserNum === data.UserNum ? data.UserNum : 0,
        NodeNum: detail?.NodeNum === data.NodeNum ? data.NodeNum : 0,
      };
      const to = data;
      new TWEEN.Tween(from)
        .to(to, 1000)
        .duration(500)
        .easing(TWEEN.Easing.Quadratic.Out)
        .onUpdate((info) => {
          const showinfo = {
            BlockHeight: Math.floor(info.BlockHeight),
            TransactionNum: Math.floor(info.TransactionNum),
            ContractNum: Math.floor(info.ContractNum),
            OrgNum: Math.floor(info.OrgNum),
            UserNum: Math.floor(info.UserNum),
            NodeNum: Math.floor(info.NodeNum),
          };
          setDetail(showinfo);
        })
        .start();
      clearTimeout(timeManage);
      let newTime = 1000;
      if (data.TransactionNum > 1000000) {
        newTime = 10000;
      } else if (data.TransactionNum > 100000) {
        newTime = 5000;
      }
      if (newTime !== waitTime) {
        setWaitTime(newTime);
      }
      timeManage = setTimeout(() => {
        setRefreshCount(refreshCount + 1);
      }, waitTime);
    },
    [detail, refreshCount, waitTime],
  );
  const updateData = useCallback(() => {
    Decimal({
      ChainId: chainId,
    })
      .then((res) => {
        if (res.Data) {
          animateCount(res.Data);
        }
      })
      .catch(() => {
        setRefreshCount(refreshCount + 1);
      });
  }, [chainId, animateCount, refreshCount]);
  useEffect(() => {
    updateData();
  }, [refreshCount]);
  return (
    <div className={style.block}>
      {/* <div className={style.block_i} onClick={() => navigate(`/${chainId}/block`)}>
        <div className={style.block_img}>
          <img src={height} />
        </div>
        <p className={style.block_count}>{detail?.BlockHeight}</p>
        <p className={style.block_name}>区块高度</p>
      </div>
      <div className={style.block_i} onClick={() => navigate(`/${chainId}/transaction`)}>
        <div className={style.block_img}>
          <img src={deal} />
        </div>
        <p className={style.block_count}>{detail?.TransactionNum}</p>
        <p className={style.block_name}>累计交易数</p>
      </div>
      <div className={style.block_i} onClick={() => navigate(`/${chainId}/contract`)}>
        <div className={style.block_img}>
          <img src={contract} />
        </div>
        <p className={style.block_count}>{detail?.ContractNum}</p>
        <p className={style.block_name}>累计合约数</p>
      </div>
      <div className={style.block_i} onClick={() => navigate(`/${chainId}/origin`)}>
        <div className={style.block_img}>
          <img src={group} />
        </div>
        <p className={style.block_count}>{detail?.OrgNum}</p>
        <p className={style.block_name}>组织数</p>
      </div>
      <div className={style.block_i} onClick={() => navigate(`/${chainId}/node`)}>
        <div className={style.block_img}>
          <img src={node} />
        </div>
        <p className={style.block_count}>{detail?.NodeNum}</p>
        <p className={style.block_name}>节点数</p>
      </div>
      <div className={style.block_i} onClick={() => navigate(`/${chainId}/user`)}>
        <div className={style.block_img}>
          <img src={user} />
        </div>
        <p className={style.block_count}>{detail?.UserNum}</p>
        <p className={style.block_name}>链上用户数</p>
      </div> */}
      <div className={`${style.block_i} ${style.block_height}`} onClick={() => navigate(`/${chainId}/block`)}>
        <div className={style.icon_c}>
          <FontAwesomeIcon icon={faCubes} className={style.icon_svg} />
        </div>
        <div className={style.block_item}>
          <p className={style.block_count}>{formatNumberToThousands(detail?.BlockHeight)}</p>
          <p className={style.block_name}>区块高度</p>
        </div>
      </div>
      <div className={`${style.block_i} ${style.block_tx}`} onClick={() => navigate(`/${chainId}/transaction`)}>
        <div className={style.icon_c}>
          <FontAwesomeIcon icon={faArrowsRotate} className={style.icon_svg} />
        </div>
        <div className={style.block_item}>
          <p className={style.block_count}>{formatNumberToThousands(detail?.TransactionNum)}</p>
          <p className={style.block_name}>累计交易数</p>
        </div>
      </div>
      <div className={`${style.block_i} ${style.block_contract}`} onClick={() => navigate(`/${chainId}/contract`)}>
        <div className={style.icon_c}>
          <FontAwesomeIcon icon={faFileContract} className={style.icon_svg} />
        </div>
        <div className={style.block_item}>
          <p className={style.block_count}>{formatNumberToThousands(detail?.ContractNum)}</p>
          <p className={style.block_name}>累计合约数</p>
        </div>
      </div>
      {/* {authType === 'permissionedwithcert' && (
        <div className={`${style.block_i} ${style.block_org}`} onClick={() => navigate(`/${chainId}/origin`)}>
          <div className={style.icon_c}>
            <FontAwesomeIcon icon={faBuildingColumns} className={style.icon_svg} />
          </div>
          <div className={style.block_item}>
            <p className={style.block_count}>{formatNumberToThousands(detail?.OrgNum)}</p>
            <p className={style.block_name}>组织数</p>
          </div>
        </div>
      )}
      <div className={`${style.block_i} ${style.block_node}`} onClick={() => navigate(`/${chainId}/node`)}>
        <div className={style.icon_c}>
          <FontAwesomeIcon icon={faAtom} className={style.icon_svg} />
        </div>
        <div className={style.block_item}>
          <p className={style.block_count}>{formatNumberToThousands(detail?.NodeNum)}</p>
          <p className={style.block_name}>节点数</p>
        </div>
      </div>
      <div className={`${style.block_i} ${style.block_user}`} onClick={() => navigate(`/${chainId}/user`)}>
        <div className={style.icon_c}>
          <FontAwesomeIcon icon={faUsers} className={style.icon_svg} />
        </div>
        <div className={style.block_item}>
          <p className={style.block_count}>{formatNumberToThousands(detail?.UserNum)}</p>
          <p className={style.block_name}>链上用户数</p>
        </div>
      </div> */}
    </div>
  );
}
