export const hex = {
  decode: (hex: string) => {
    const arr = hex.split('');
    let out = '';
    for (let i = 0; i < arr.length / 2; i++) {
      const charValue = String.fromCharCode(parseInt(hex.substr(i * 2, 2), 16));
      out += charValue;
    }
    return out;
  },
  encode: (str: string) => {
    let val = '';
    if (!str || !str.length) {
      return str;
    }
    for (let i = 0; i < str.length; i++) {
      val += str.charCodeAt(i).toString(16);
    }
    return val;
  },
};
