import React, { useCallback } from 'react';
import { Button, Modal } from 'tea-component';

// const version = '1.5.2';

export default function PluginDownloadModel({ visible, onClose }: { visible: boolean; onClose: () => void }) {
  const title = (window as any).chainMaker ? '安装插件版本过低' : '未安装插件钱包';
  const toChainmaker = useCallback(() => {
    window.open('https://portalbackend.chainmaker.org.cn/versionMgr/url/8');
    onClose();
  }, []);
  const toChrome = useCallback(() => {
    window.open(
      'https://chrome.google.com/webstore/detail/chainmaker-smartplugin/ekjipgejfjfhjingjpgbmkcppnpfdggc?utm_source=chrome-ntp-icon'
    );
    onClose();
  }, []);

  return (
    <>
      <Modal size="s" visible={visible} caption={title} onClose={onClose}>
        <Modal.Body>
          请先下载长安链Web3插件钱包（SmartPlugin）后再继续操作。官方渠道下载适用于无VPN的用户，谷歌应用商城下载适用于有VPN的用户，更多使用说明请
          <a
            target="_blank"
            href="https://docs.chainmaker.org.cn/dev/%E9%95%BF%E5%AE%89%E9%93%BEWeb3%E6%8F%92%E4%BB%B6.html"
            rel="noreferrer"
          >
            查看使用说明文档
          </a>
          。
        </Modal.Body>
        <Modal.Footer>
          <Button style={{ width: 120 }} type="primary" onClick={toChainmaker}>
            官方渠道下载
          </Button>
          <Button style={{ width: 120 }} type="weak" onClick={toChrome}>
            应用商城下载
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
