import React, { useCallback, useEffect, useMemo, useState } from 'react';
import liststyle from '../../list.module.scss';
import { Text, Table, TableColumn } from 'tea-component';
import { TransferItem } from '@src/models';
import { GetTransferList } from '@src/utils/apis';
import { Link, useParams } from 'react-router-dom';
import { formatUnixTime, emptyToStrikethrough } from '@src/utils/tools';
import { RootReducer } from '@src/store';
import { useSelector } from 'react-redux';
const { pageable } = Table.addons;

export default function Transfer({
  type
}:{
  type?:string;
}) {
  const { contractName } = useParams();
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId = useMemo(() => currentChain?.ChainId, [currentChain]);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [list, setList] = useState<TransferItem[]>([]);
  const getList = useCallback(() => {
    if (!contractName) {
      return;
    }
    GetTransferList({
      Offset: pageIndex - 1,
      ChainId: chainId,
      ContractName: contractName,
      Limit: pageSize,
    }).then((res) => {
      if (res.GroupList) {
        setList(res.GroupList);
        setRecordCount(res.TotalCount);
      }
    });
  }, [chainId, contractName, pageIndex, pageSize]);
  useEffect(() => {
    getList();
  }, [contractName, chainId, pageIndex, pageSize]);
  const columns = useMemo<TableColumn[]>(() => {
    const list: TableColumn<TransferItem>[] = [
      {
        key: 'BlockTime',
        header: '上链时间',
        align: 'left',
        render: ({ BlockTime }) => formatUnixTime(BlockTime),
        width: 180,
      },
      {
        key: 'TxId',
        header: '交易Id',
        align: 'left',
        width: 220,
        render: ({ TxId }) => <Link to={`/${chainId}/transaction/${TxId}`}>{TxId}</Link>,
      },
      {
        key: 'ContractMethod',
        header: '合约方法',
        align: 'left',
        render: ({ ContractMethod }) => emptyToStrikethrough(ContractMethod),
      },
      {
        key: 'From',
        header: '从',
        align: 'left',
        render: ({ From }) => emptyToStrikethrough(From),
      },
      {
        key: 'To',
        header: '转到',
        align: 'left',
        render: ({ To }) => emptyToStrikethrough(To),
      },
      {
        key: 'TxStatusCode',
        header: '交易状态',
        align: 'left',
        width: 100,
        render: ({ Status }) => <Text theme={Status === 'SUCCESS' ? 'success' : 'danger'}>{Status}</Text>,
      },
    ];
    if (type === 'CMNFA') {
      list.splice(5, 0,
        {
          key: 'TokenId',
          header: 'TokenId',
          align: 'left',
          render: ({ TokenId }) => (
            <Link to={`/${chainId}/nft?tokenId=${TokenId}&contractName=${contractName}`}>{TokenId}</Link>
          ),
        });
      list.push({
        key: 'TokenId',
        header: '操作',
        align: 'center',
        width: 100,
        render: ({ TokenId }) => <Link to={`/${chainId}/nft?tokenId=${TokenId}&contractName=${contractName}`}>查看NFT</Link>,
      });
    }
    if (type === 'CMDFA') {
      list.splice(5, 0,
        {
          key: 'Amount',
          header: '数量'
        });
      list.push({
        key: 'TxId',
        header: '操作',
        align: 'center',
        width: 100,
        render: ({ TxId }) => <Link to={`/${chainId}/transaction/${TxId}`}>查看</Link>,
      });
    }
    return list;
  }, [type]);
  return (
    <Table
      className={liststyle.table}
      compact={false}
      records={list}
      recordKey="TxId"
      bordered={true}
      disableTextOverflow={true}
      columns={columns}
      addons={[
        pageable({
          recordCount,
          onPagingChange: (query) => {
            if (query?.pageIndex) {
              setPageIndex(query.pageIndex);
            }
            if (query?.pageSize) {
              setPageSize(query.pageSize);
            }
          },
        }),
      ]}
    />
  );
}
