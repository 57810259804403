import { isOlderThanVersion } from '@src/utils/tools';

const version = '1.5.2';

export const checkInstallPlugin = () => {
  if (!(window as any).chainMaker || isOlderThanVersion((window as any).chainMaker.version, version)) {
    return false;
  }
  return true;
};
